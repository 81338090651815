import { useTriggerLogin } from '@/hooks/shared/useTriggerLogin';
import { Button, Stack, Text } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { IconExclamationCircle } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

interface PageErrorProps {
    error: Error | null;
    message: string;
}

export default function PageError({ error, message }: PageErrorProps) {
    const router = useRouter();
    const { triggerLogin } = useTriggerLogin();

    const isTokenExpired =
        error?.message.includes('Missing Refresh Token') ||
        error?.message.includes('401') ||
        error?.message.includes('invalid refresh token');
    const noPracticesFound = error?.message.includes('No practices found');

    useEffect(() => {
        // Attempt to login in case the token has expired
        if (isTokenExpired) {
            triggerLogin();
        } else {
            // Manually capture the error with Sentry
            Sentry.captureException(error, { extra: { responseMessage: error?.message } });
        }
    }, [error, isTokenExpired, router, triggerLogin]);

    return (
        <Stack align="center">
            {/* Make sure nothing is rendered if isTokenExpired = true, while user is redirected to login page */}
            {!isTokenExpired && (
                <>
                    <IconExclamationCircle color="var(--mantine-color-red-filled)" size={96} />

                    <Text ta="center" c="red" fw="bold" size="xl">
                        {message}
                    </Text>

                    {/* Rare edge case - no practices for the user. Prompt them to log out and log back in, which would apply Auth0 metadata to their user */}
                    {noPracticesFound && (
                        <Button component="a" href="/logout">
                            Log out
                        </Button>
                    )}
                </>
            )}
        </Stack>
    );
}

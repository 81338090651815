import { useAuth0 } from '@auth0/auth0-react';

export function useTriggerLogin() {
    const { loginWithRedirect } = useAuth0();

    function triggerLogin() {
        const returnTo = window?.location?.pathname + window?.location?.search;

        // Store the returnTo URL if it exists, so we can redirect back after login
        if (returnTo && returnTo !== '/') {
            loginWithRedirect({
                appState: { returnTo },
            });
        } else {
            loginWithRedirect();
        }
    }

    return { triggerLogin };
}

import { Center, Loader, MantineColor } from '@mantine/core';

interface FullScreenLoaderProps {
    color?: MantineColor;
}

export default function FullScreenLoader({ color = 'rx-purple' }: FullScreenLoaderProps) {
    return (
        <Center style={{ minHeight: '100vh' }}>
            <Loader size={48} color={color} />
        </Center>
    );
}
